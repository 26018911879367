/* You can add global styles to this file, and also import other style files */
.card-header {
  background-color: rgb(213, 213, 230) !important;
  height: 45px !important;
  padding: 5px !important;
  padding-left: 10px !important;
}

.filterdropdown{
  background: #eee;
  border: 1px solid #bbb;
  color: #bbb;
  border-radius: 2px;
  font-size: 14px;
  line-height: 14px;
}
.filterdropdownactive{
  color: #005b9c;
  background: #eee;
  border: 2px solid #005b9c;
  border-radius: 2px;
  font-size: 14px;
  line-height: 14px;
}
a.filterlink{
  font-size: 12px;
}
.options {
  height: 150px;
  overflow-y: scroll;
}
